import { Admin, Resource, CustomRoutes, useTranslate } from 'react-admin';
import { Route } from 'react-router-dom';
import { lazy } from 'react';
import './App.css';
import CustomLayout from '../../components/customLayout/CustomLayout';
import dataProvider from '../../provider/dataProvider';
import authProvider from '../../provider/authProvider';
import i18nProvider from '../../provider/i18nProvider';
const UsersPage = lazy(() => import('../../components/pages/usersPage/UsersPage'));
const ProfilePage = lazy(() => import('../../components/pages/profilePage/ProfilePage'));
const CommonEditProfilePages = lazy(
  () => import('../../components/pages/commonEditProfilePages/CommonEditProfilePages'),
);
const CreateProfilePage = lazy(() => import('../../components/pages/createProfilePage/CreateProfilePage'));
const AppsPage = lazy(() => import('../../components/pages/appsPage/AppsPage'));
const CreateAppPage = lazy(() => import('../../components/pages/createAppPage/CreateAppPage'));
const DashboardPage = lazy(() => import('../../components/pages/dashboardPage/dashboardPage'));
const LoginPage = lazy(() => import('../../components/pages/loginPage/loginPage'));
const ShowAppPage = lazy(() => import('../../components/pages/showAppPage/ShowAppPage'));
const OperationPage = lazy(() => import('../../components/pages/operationsPage/OperationsPage'));
const ApprovalsPage = lazy(() => import('../../components/pages/approvalsPage/ApprovalsPage'));
const ShowApprovalPage = lazy(() => import('../../components/pages/showApprovalPage/ShowApprovalPage'));
const ShowRolePage = lazy(() => import('../../components/pages/showRolePage/ShowRolePage'));
const ShowResourcePage = lazy(() => import('../../components/pages/showResourcePage/ShowResourcePage'));
const ConfirmEmailPage = lazy(() => import('../../components/pages/confirmEmailPage/ConfirmEmailPage'));
const CreateContragentPage = lazy(() => import('../../components/pages/createContragentPage/CreateContragentPage'));
const EditGroupPage = lazy(() => import('../../components/pages/editGroupPage/EditGroupPage'));
const GroupsPage = lazy(() => import('../../components/pages/groupsPage/GroupsPage'));
const ShowGroupPage = lazy(() => import('../../components/pages/showGroupPage/ShowGroupPage'));
const LoginLocalPage = lazy(() => import('../../components/pages/loginLocalPage/LoginLocalPage'));
const ConnectorsPage = lazy(() => import('../../components/pages/connectorsPage/ConnectorsPage'));
const UserAttributesPage = lazy(() => import('../../components/pages/userAttributesPage/UserAttributesPage'));
const EditFieldPage = lazy(() => import('../../components/pages/editFieldPage/EditFieldPage'));
const EditConnectorPage = lazy(() => import('../../components/pages/editConnectorPage/EditConnectorPage'));
const ErrorInfoPage = lazy(() => import('../../components/pages/errorInfoPage/ErrorInfoPage'));

function App() {
  const translate = useTranslate();

  return (
    <div className="App">
      <Admin
        layout={CustomLayout}
        loginPage={LoginPage}
        dataProvider={dataProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        dashboard={DashboardPage}
      >
        {
          <CustomRoutes>
            <Route path="/create_contragent" element={<CreateContragentPage />} />
          </CustomRoutes>
        }
        {
          <CustomRoutes noLayout>
            <Route path="/confirm_email" element={<ConfirmEmailPage />} />
            <Route path="/local" element={<LoginLocalPage />} />
            <Route path="/error" element={<ErrorInfoPage />} />
          </CustomRoutes>
        }
        <Resource
          name={'app'}
          options={{ label: translate('operations.entities.APP') }}
          list={AppsPage}
          create={CreateAppPage}
          show={ShowAppPage}
          edit={CreateAppPage}
        />
        <Resource
          name={'user'}
          options={{ label: translate('operations.entities.USER') }}
          list={UsersPage}
          show={ProfilePage}
          edit={CommonEditProfilePages}
          create={CreateProfilePage}
        />
        <Resource name="operation" list={<OperationPage />} />
        <Resource name={'approval'} list={ApprovalsPage} show={ShowApprovalPage} />
        <Resource name="resource" show={ShowResourcePage} />
        <Resource name="role" show={ShowRolePage} />
        <Resource name="group" list={GroupsPage} show={ShowGroupPage} create={EditGroupPage} edit={EditGroupPage} />
        <Resource
          name="connector"
          list={ConnectorsPage}
          create={EditConnectorPage}
          show={EditConnectorPage}
          edit={EditConnectorPage}
        />
        <Resource name="fields" list={UserAttributesPage} create={EditFieldPage} edit={EditFieldPage} />
        <Resource name="profile" show={ProfilePage} edit={CommonEditProfilePages} />
      </Admin>
    </div>
  );
}

export default App;
