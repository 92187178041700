import * as React from 'react';
import {
  AppBar,
  AppBarProps,
  ToggleThemeButton,
  RaThemeOptions,
  useThemesContext,
  useTheme as useThemeRa,
  useStore,
} from 'react-admin';
import { createTheme, Typography, useTheme } from '@mui/material';
import styles from './CustomHeader.module.css';
import ProfileMenu from '../popovers/profileMenu/ProfileMenu';
import { createDarkThemeOptions, createLightThemeOptions } from '../../utils/utils';
import { ModalWrapper } from '../modals/modalWrapper/ModalWrapper';
import dataProvider from '../../provider/dataProvider';

const lightOpt: RaThemeOptions = createLightThemeOptions();

const darkOpt: RaThemeOptions = createDarkThemeOptions();

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    ...darkOpt.palette,
  },
});

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    ...lightOpt.palette,
  },
});

const CustomHeader = (props: AppBarProps) => {
  const [localThemeStore] = useStore('theme');
  const [settings, setSettingsState] = useStore('settings', JSON.stringify({}));
  const {
    lightTheme: lightThemeRa,
    darkTheme: darkThemeRa,
    defaultTheme,
  } = useThemesContext({
    lightTheme,
    darkTheme,
    defaultTheme: (localThemeStore && localThemeStore?.palette?.mode) || 'light',
  });
  const [, setTheme] = useThemeRa(defaultTheme === 'light' ? lightThemeRa : darkThemeRa);
  const theme = useTheme();

  React.useEffect(() => {
    dataProvider
      .getList('settings', { sort: { order: 'ASC', field: 'id' }, pagination: { page: 1, perPage: 99 }, filter: {} })
      .then(({ data }) => {
        const settings = {};
        data.map((item) => (settings[item.id] = item));
        setSettingsState(JSON.stringify(settings));
      });
  }, [dataProvider]);

  React.useEffect(() => {
    setTheme(defaultTheme === 'light' ? lightThemeRa : darkThemeRa);
  }, []);

  return (
    <AppBar
      {...props}
      sx={{
        '&.MuiPaper-root': {
          boxShadow: 'none',
          borderBottom: '1px solid #e5e5e5',
        },
        '&.RaAppBar-title': {
          flex: 1,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        },
        '& .app-loader': {
          color: theme.palette.text.primary,
        },
        '& .RaAppBar-toolbar': {
          backgroundColor: theme.palette.background.paper,
          backgroundImage: 'none',
        },
        '& .MuiSvgIcon-root': {
          '& path': {
            fill: theme.palette.text.primary,
          },
        },
        '& .MuiTypography-root': {
          color: theme.palette.text.primary,
        },
      }}
      userMenu={<ProfileMenu />}
    >
      <ModalWrapper />
      <div className={styles.root}>
        <img src={settings ? JSON.parse(settings).app?.settings.APP_LOGO : null} alt="logo" className={styles.logo} />
        <Typography variant="h6">{settings ? JSON.parse(settings).app?.settings.APP_NAME : null}</Typography>
      </div>
      <ToggleThemeButton lightTheme={lightTheme} darkTheme={darkTheme} />
    </AppBar>
  );
};

export default CustomHeader;
